<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="expert">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      v-on:keyup="search"
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Member"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :per-page="0"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(name)="row">
                  <span class="text-center">
                    <template
                      v-if="
                        (row.item.first_name != null &&
                          row.item.first_name != '') ||
                        (row.item.last_name != null && row.item.last_name != '')
                      "
                    >
                      <span class="email-word-break"
                        >{{ row.item.first_name }}
                        {{ row.item.last_name }}</span
                      >
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == 'null' ||
                        row.item.last_name == 'null'
                      "
                    >
                      <span class="email-word-break">---</span>
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == '' || row.item.last_name == ''
                      "
                    >
                      <span>---</span>
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == null ||
                        row.item.last_name == null
                      "
                    >
                      <span>---</span>
                    </template>
                    <template v-else>
                      <span>---</span>
                    </template>
                  </span>
                </template>
                <template v-slot:cell(city)="row">
                  <span v-if="row.item.city" class="text-center">{{
                    row.item.city
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(country)="row">
                  <span v-if="row.item.country" class="text-center">{{
                    row.item.country
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(edit)="row">
                  <i
                    class="fa fa-edit edit-i cursor-pointer"
                    @click="passwordModal(row.item.id)"
                  ></i>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    >
                    </b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <b-modal
          id="bvModal"
          ref="modal"
          title="Password Verification"
          @ok="handlePassword"
          @cancel="closeModal"
          v-model="showPasswordModal"
          no-close-on-backdrop
          centered
          hide-header-close
          ok-title="Submit"
          cancel-title="Close"
        >
          <div class="fr_main_grid">
            <div class="fr_fname">
              <span class="fr_fname_label">Member Name : </span>
              <span class="fr_fname_input content-text">admin</span>
            </div>
          </div>
          <form ref="form" @submit.stop.prevent="handleSubmitPassword">
            <b-form-group label-for="name-input" class="mt-4">
              <label>Password <span style="color: red">*</span></label>
              <b-form-input
                v-model="form.password"
                type="password"
              ></b-form-input>
              <div
                v-if="submitted && !$v.form.password.required"
                class="invalid-feedback"
              >
                password is required.
              </div>
            </b-form-group>
          </form>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import gpDataMixin from "../../../mixins/ModuleJs/gpdata";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, gpDataMixin],
  data() {
    return {
      title: "GP DATA",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  validations: {
    form: {
      password: { required },
    },
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
